
.custom-selected-tab {
  color: orange;
  border-bottom: 3px solid orange !important;
  font-weight: 600;
}
.custom-tab-list {
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
  display: flex;
  gap: 20px;
  cursor: pointer;

  /* Add any other styling you need */
}
.custom-tab{
    border: white;
}

