.gold-plan {
  background: linear-gradient(105.74deg, #ffcd29 8.19%, #efa716 99.75%);
}
.gold-plan-border {
  border: 1px solid;
  border-image-source: linear-gradient(
    105.74deg,
    #ffcd29 8.19%,
    #efa716 99.75%
  );
  background: linear-gradient(105.74deg, #ffcd29 8.19%, #efa716 99.75%);
}

.platinum-plan {
  background: linear-gradient(
    100.24deg,
    #a3d1fc 0%,
    #8ac7ff 0.01%,
    #296cab 99.19%
  );
}
.platinum-plan-border {
  border: 1px solid;
  border-image-source: linear-gradient(
    100.24deg,
    #a3d1fc 0%,
    #8ac7ff 0.01%,
    #296cab 99.19%
  );
}
